// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-components-footer-index-js": () => import("./../src/pages/Home/components/Footer/index.js" /* webpackChunkName: "component---src-pages-home-components-footer-index-js" */),
  "component---src-pages-home-components-header-index-js": () => import("./../src/pages/Home/components/Header/index.js" /* webpackChunkName: "component---src-pages-home-components-header-index-js" */),
  "component---src-pages-home-components-join-us-index-js": () => import("./../src/pages/Home/components/JoinUs/index.js" /* webpackChunkName: "component---src-pages-home-components-join-us-index-js" */),
  "component---src-pages-home-components-locations-index-js": () => import("./../src/pages/Home/components/Locations/index.js" /* webpackChunkName: "component---src-pages-home-components-locations-index-js" */),
  "component---src-pages-home-components-product-description-data-insigh-section-js": () => import("./../src/pages/Home/components/ProductDescription/DataInsighSection.js" /* webpackChunkName: "component---src-pages-home-components-product-description-data-insigh-section-js" */),
  "component---src-pages-home-components-product-description-index-js": () => import("./../src/pages/Home/components/ProductDescription/index.js" /* webpackChunkName: "component---src-pages-home-components-product-description-index-js" */),
  "component---src-pages-home-components-quotes-index-js": () => import("./../src/pages/Home/components/Quotes/index.js" /* webpackChunkName: "component---src-pages-home-components-quotes-index-js" */),
  "component---src-pages-home-components-sponsors-index-js": () => import("./../src/pages/Home/components/Sponsors/index.js" /* webpackChunkName: "component---src-pages-home-components-sponsors-index-js" */),
  "component---src-pages-home-components-statistics-index-js": () => import("./../src/pages/Home/components/Statistics/index.js" /* webpackChunkName: "component---src-pages-home-components-statistics-index-js" */),
  "component---src-pages-home-components-trends-index-js": () => import("./../src/pages/Home/components/Trends/index.js" /* webpackChunkName: "component---src-pages-home-components-trends-index-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */)
}

